<script lang="ts" setup>
import { watch } from "vue";
import {
  useAppKit,
  useAppKitEvents,
  useAppKitAccount,
} from "@reown/appkit/vue";
import type { SuggestedCampaignDto } from "~~/_api_generated_";

const emit = defineEmits(["saved"]);

const props = defineProps({
  campaign: {
    type: Object as PropType<SuggestedCampaignDto> | undefined,
    required: false,
    default: undefined,
  },
});

const appKit = getGlobalAppKit();
const modal = useAppKit();
const account = useAppKitAccount();
const events = useAppKitEvents();
const lock = ref({});

async function openModal() {
  // User clicked "Add a wallet" button. So lets him decide which wallet to connect.
  // Doing this will always show the modal.
  if (useAppKitAccount().value.address) {
    await appKit.disconnect();
  }

  globalAppKitLock.lockingObject = lock.value;
  modal.open();
  trackEvent("selecting_wallet");
}

watch(events, (newEvents) => {
  console.log("WalletConnector event watcher", {
    newEvents: JSON.parse(JSON.stringify(newEvents)),
  });

  if (
    (newEvents.data.event === "CONNECT_SUCCESS" ||
      (newEvents.data.event === "MODAL_CLOSE" &&
        newEvents.data.properties.connected === true)) &&
    globalAppKitLock.lockingObject === lock.value
  ) {
    globalAppKitLock.lockingObject = null;
    resolveWalletConnected();
  }
});

async function resolveWalletConnected() {
  console.log("resolving wallet address: " + JSON.stringify(account.value));
  if (!account.value.caipAddress) {
    errorMessage("Wallet connection failed");
    return;
  }

  try {
    if (props.campaign) {
      const campaignId = props.campaign.id;
      await apiService.joinCampaign(campaignId, {
        walletCaipAddress: account.value.caipAddress,
      });
    } else {
      const response = await apiService.saveWallet({
        caipAddress: account.value.caipAddress,
      });
      if (response.data.reused) {
        successMessage(`Wallet ${account.value.address} already connected.`);
      } else {
        trackEvent("wallet_connected");
        successMessage("Wallet connected.");
      }
    }
    emit("saved");
  } catch (error) {
    console.error(error);
    trackEvent("failed_to_connect_wallet");
    errorMessage("Adding wallet failed.");
  }
}
</script>

<template>
  <button @click="openModal">
    <slot />
  </button>
</template>
